import { useErrorHandling } from '@/composables'
import { ENDPOINTS } from '@/endpoints'
import { type APIResponse } from '@/types'
import type {
    MarketDataGovernmentSecurityGovernmentInvestmentIssues, 
    MarketDataGovernmentSecurityMalaysianGovernmentSecurities,
    MarketDataGovernmentSecurityShortTermBills,
} from '@/types'
import axios from 'axios'

export const getMarketDataGovernmentSecurityMalaysianGovernmentSecurities = async () => {
    const request = axios.get<APIResponse<MarketDataGovernmentSecurityMalaysianGovernmentSecurities>>(
        `${ENDPOINTS.MARKET_DATA_V1}/government-security/malaysian-government-securities`,
    )

    const response = await useErrorHandling(request)

    return response.data.data
}

export const getMarketDataGovernmentSecurityGovernmentInvestmentIssues = async () => {
    const request = axios.get<APIResponse<MarketDataGovernmentSecurityGovernmentInvestmentIssues>>(
        `${ENDPOINTS.MARKET_DATA_V1}/government-security/government-investment-issues`,
    )

    const response = await useErrorHandling(request)

    return response.data.data
}

export const getMarketDataGovernmentSecurityShortTermBills = async () => {
    const request = axios.get<APIResponse<MarketDataGovernmentSecurityShortTermBills>>(
        `${ENDPOINTS.MARKET_DATA_V1}/government-security/short-term-bills`,
    )

    const response = await useErrorHandling(request)

    return response.data.data
}
