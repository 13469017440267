import { importHighcharts } from '@/lib/highcharts'
import { BlockModule } from '../common'
import { doIfInViewOrDefer, chartLineColors } from './common'
import { getMarketDataExternalSectorDirectInvestments } from '@/api'
import { defineComponent } from 'vue'
import ChartShareButtons from '@/components/website/ChartShareButtons.vue'

new BlockModule({
    selector: '.block-market-data-external-sector-direct-investments',
    vueComponent: defineComponent({
        components: { ChartShareButtons },
        setup() {},
    }),
    async setupBlock(el, onTeardown, blockContext) {
        const chartData = await getMarketDataExternalSectorDirectInvestments()

        const setupChart = async () => {
            const chartEl = el.querySelector('.chart-container')
            if (!chartEl) return

            const Highcharts = await importHighcharts()

            Highcharts.setOptions({
                lang: {
                    numericSymbols: ['K', 'M', 'B', 'T']
                }
            })

            const chart = Highcharts.chart({
                chart: {
                    type: 'line',
                    renderTo: chartEl as HTMLElement,
                    marginTop: 40,
                },
                title: {
                    text: '',
                },
                xAxis: chartData.map((x) => ({
                    categories: x.data.map((x) => x.x.toString()),
                    lineColor: '#DBDBDB',
                    allowDecimals: false,
                })),
                legend: {
                    align: 'right',
                    verticalAlign: 'top',
                },
                yAxis: {
                    color: '#DBDBDB',
                    title: {
                        text: 'RM billion',
                        align: 'high',
                        rotation: 0,
                        reserveSpace: false,
                        textAlign: 'left',
                        y: -20,
                    },
                },
                credits: { enabled: false },
                series: chartData.map((x, index) => ({
                    name: x.category,
                    type: 'line',
                    color: chartLineColors[index],
                    data: x.data.map((x) => x.y),
                })),
                exporting: {
                    enabled: false,
                    filename: 'external-sector-direct-investments',
                },
                tooltip: {
                    valueDecimals: 1,
                },
                responsive: {
                    rules: [
                        {
                            condition: {
                                maxWidth: 1024,
                            },
                            chartOptions: {
                                legend: {
                                    align: 'center',
                                    verticalAlign: 'bottom',
                                },
                            },
                        },
                    ],
                },
            })

            const download = el.querySelector<HTMLButtonElement>('.download-btn')
            if (download) {
                download.addEventListener('click', () => {
                    chart.downloadCSV()
                })
            }

            onTeardown(() => {
                chart.destroy()
            })
        }

        doIfInViewOrDefer(() => {
            setupChart()
        }, blockContext)
    },
})
