<template>
    <Tabs :default-value="defaultTab" v-model="modelValue">
        <div class="relative flex flex-col justify-between lg:flex-row">
            <TabsList
                class="topic-tabs lg:top-navbar-offset flex w-full flex-col self-start lg:sticky lg:w-4/12 lg:pt-5 lg:transition-[top] lg:duration-300 xl:w-3/12">
                <slot name="topics" :scrollToElement="scrollToElement"></slot>
            </TabsList>
            <div
                ref="stickyElRef"
                class="top-navbar-offset sticky z-40 -mx-5 h-auto min-h-18 opacity-0 transition-[top,opacity] [transition-duration:300ms,200ms] lg:hidden"
                :class="
                    isStuck ? 'pointer-events-auto opacity-100' : 'pointer-events-none opacity-0'
                ">
                <Sheet v-model:open="isDialogOpen">
                    <SheetTrigger as-child>
                        <button
                            class="absolute left-1/2 -ml-[50vw] flex h-auto min-h-18 w-screen items-center border-y-2 border-y-yellow bg-bgCobalt p-5 text-start text-lg font-bold text-white">
                            <span class="flex-grow">{{ modelValue }}</span>
                            <svg
                                :class="isDialogOpen ? 'rotate-180' : ''"
                                class="h-[14px] w-[14px] text-rubyRed"
                                width="10"
                                height="6"
                                viewBox="0 0 10 6"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M0.646447 0.646447C0.841709 0.451184 1.15829 0.451184 1.35355 0.646447L5 4.29289L8.64645 0.646447C8.84171 0.451184 9.15829 0.451184 9.35355 0.646447C9.54882 0.841709 9.54882 1.15829 9.35355 1.35355L5.35355 5.35355C5.15829 5.54882 4.84171 5.54882 4.64645 5.35355L0.646447 1.35355C0.451184 1.15829 0.451184 0.841709 0.646447 0.646447Z"
                                    fill="currentColor" />
                            </svg>
                        </button>
                    </SheetTrigger>
                    <SheetContent side="top" class="p-0" hide-close-button>
                        <VisuallyHidden>
                            <SheetTitle>Navigate a topic</SheetTitle>
                            <SheetDescription>
                                Select a topic that you would like to navigate to
                            </SheetDescription>
                        </VisuallyHidden>
                        <div class="font-bold text-midGrey hover:text-primary">
                            <slot name="mobile-dropdown-topics" :setActiveTab="tabAction"> </slot>
                        </div>
                    </SheetContent>
                </Sheet>
            </div>
            <div
                id="topicsContent"
                class="scroll-margin-top-topics-offset w-full lg:w-7/12 lg:pt-0 xl:w-8/12"
                :class="stickyActive ? 'pt-[71px]' : 'pt-10'">
                <slot
                    name="topics-content"
                    :setActiveTab="tabAction"
                    :isFirstBoot="isFirstBoot"></slot>
            </div>
        </div>
    </Tabs>
</template>

<script setup lang="ts">
import { Tabs, TabsList } from '@/components/ui/tabs'
import { nextTick, onMounted, ref, watch } from 'vue'
import { useIsStuck } from '@/composables'
import { Sheet, SheetContent, SheetDescription, SheetTitle, SheetTrigger } from '../ui/sheet'
import { VisuallyHidden } from 'radix-vue'

const { isStuck, start, stickyElRef } = useIsStuck()

defineProps({
    defaultTab: String,
    list: Array,
})

const modelValue = defineModel<string>('defaultTab')
const stickyActive = ref(false)
const isDialogOpen = ref(false)
const isFirstBoot = ref(false)

const scrollToElement = () => {
    const element = document.getElementById('topicsContent')
    if (element) {
        setTimeout(() => element.scrollIntoView({ behavior: 'smooth', block: 'start' }), 100)
    }
}

const tabAction = (tabId: string) => {
    isDialogOpen.value = false
    modelValue.value = tabId
    scrollToElement()
}

onMounted(() => {
    start()
})

watch(modelValue, () => {
    nextTick(() => {
        window.dispatchEvent(new CustomEvent('blocks:reinit'))
        scrollToElement()
    })
})
</script>
