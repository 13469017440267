<script setup lang="ts">
import {
    useEventOrganizersAsync,
    useEventTypesAsync,
    useEventPlatformsAsync,
    useEventAudiencesAsync,
} from '@/datasources'
import { AutocompleteControl } from '@/components/website/forms/autocomplete-control'
import { SelectControlField } from '@/components/website/forms/select-control'
import { CalendarIcon, ListIcon } from 'lucide-vue-next'
import { IconFilter } from '../icons'
import { computed, defineAsyncComponent, reactive, ref, watch } from 'vue'
import MonthYearPicker from './MonthYearPicker.vue'
import { breakpointsTailwind, createReusableTemplate, useBreakpoints } from '@vueuse/core'
import {
    Drawer,
    DrawerClose,
    DrawerContent,
    DrawerDescription,
    DrawerFooter,
    DrawerTitle,
    DrawerTrigger,
} from '@/components/ui/drawer'
import { VisuallyHidden } from 'radix-vue'
import { BnmButton } from '../button'
import { Separator } from '@/components/ui/separator'
import { CalendarDate } from '@internationalized/date'
import EventsListingList from './EventsListingList.vue'

const EventsListingCalendar = defineAsyncComponent(() => import('./EventsListingCalendar.vue'))

const [FiltersTemplate, UseFiltersTemplate] = createReusableTemplate()

const breakpoints = useBreakpoints(breakpointsTailwind)
const mdAndGreater = breakpoints.greater('md')

const sortByList = [
    { value: '1', label: 'Oldest > Newest' },
    { value: '2', label: 'Newest > Oldest' },
    { value: '3', label: 'A > Z' },
    { value: '4', label: 'Z > A' },
]

const organiserListingAsync = useEventOrganizersAsync()
const typeListingAsync = useEventTypesAsync()
const platformListingAsync = useEventPlatformsAsync()
const audienceListingAsync = useEventAudiencesAsync()

const isCalendarView = ref(false)

const setCalendarViewActive = (value: boolean) => {
    isCalendarView.value = value
}

// For the parent component, just let it manage the filters
// Let the views decide how they want to use the filters

const filters = reactive({
    startDate: '' as string,
    endDate: '' as string,
    monthYearCalendarDate: undefined as CalendarDate | undefined,
    organiser: [] as string[],
    type: [] as string[],
    platform: [] as string[],
    audience: [] as string[],
    sortMode: '1',
    pageNumber: 1,
    pageSize: 10,
})

const filterCount = computed(() => {
    let count = 0

    if (filters.monthYearCalendarDate) {
        count++
    }

    count += filters.organiser.length
    count += filters.type.length
    count += filters.platform.length
    count += filters.audience.length

    return count
})

const onClearFilters = () => {
    filters.startDate = ''
    filters.endDate = ''
    filters.organiser = []
    filters.type = []
    filters.platform = []
    filters.audience = []
    filters.sortMode = '1'
    filters.pageNumber = 1
    filters.pageSize = 10
    filters.monthYearCalendarDate = undefined
}

watch(mdAndGreater, (newVal) => {
    if (!newVal) {
        isCalendarView.value = false
    }
})
</script>

<template>
    <FiltersTemplate>
        <div class="flex flex-col-reverse md:flex-col">
            <div class="border-b border-lightGrey py-8 md:pt-0">
                <!-- Desktop Filters -->
                <div class="container mb-8 grid gap-5 md:grid-cols-5">
                    <MonthYearPicker
                        v-model="filters.monthYearCalendarDate as CalendarDate"
                        label="Month"
                        placeholder="Select Month"
                        class="[&_label]:font-bold">
                    </MonthYearPicker>
                    <AutocompleteControl
                        name="organisers"
                        label="Organiser"
                        :items="organiserListingAsync"
                        item-value="key"
                        item-text="name"
                        multiple
                        placeholder="Select Organiser"
                        v-model="filters.organiser"
                        class="[&_label]:font-bold">
                    </AutocompleteControl>
                    <AutocompleteControl
                        name="type"
                        label="Type"
                        :items="typeListingAsync"
                        item-value="key"
                        item-text="name"
                        multiple
                        placeholder="Select Type"
                        v-model="filters.type"
                        class="[&_label]:font-bold">
                    </AutocompleteControl>
                    <AutocompleteControl
                        name="platform"
                        label="Platform"
                        :items="platformListingAsync"
                        item-value="key"
                        item-text="name"
                        multiple
                        placeholder="Select Platform"
                        v-model="filters.platform"
                        class="[&_label]:font-bold">
                    </AutocompleteControl>
                    <AutocompleteControl
                        name="audience"
                        label="Audience"
                        :items="audienceListingAsync"
                        item-value="key"
                        item-text="name"
                        multiple
                        placeholder="Select Audience"
                        v-model="filters.audience"
                        class="[&_label]:font-bold">
                    </AutocompleteControl>
                </div>
            </div>
            <div class="border-y border-lightGrey md:border-t-0">
                <div
                    class="container flex h-18 items-center justify-between text-sm font-bold text-midGrey">
                    <div class="flex items-center gap-4">
                        <div v-if="!isCalendarView" class="flex items-center">
                            <span class="me-2 text-nowrap font-light body-2">Sort By</span>
                            <SelectControlField
                                :items="sortByList"
                                item-text="label"
                                item-value="value"
                                name="sort"
                                v-model="filters.sortMode"
                                class="text-primary"
                                trigger-class="border-0 focus:ring-0 focus:ring-offset-0 gap-2 p-0  body-2"
                                select-content-align="center">
                            </SelectControlField>
                        </div>
                        <Separator v-if="!isCalendarView" class="h-5" orientation="vertical">
                        </Separator>
                        <button @click="onClearFilters()">
                            <span class="text-primary underline body-2">Clear Filters</span>
                        </button>
                    </div>

                    <div v-if="mdAndGreater" class="flex divide-x">
                        <button
                            @click="setCalendarViewActive(false)"
                            class="flex items-center space-x-2 pr-4"
                            :class="!isCalendarView ? 'text-primary' : ''">
                            <ListIcon class="h-[1.125rem] w-[1.125rem]"></ListIcon>
                            <span>List View</span>
                        </button>
                        <button
                            @click="setCalendarViewActive(true)"
                            class="flex items-center space-x-2 pl-4"
                            :class="isCalendarView ? 'text-primary' : ''">
                            <CalendarIcon class="h-[1.125rem] w-[1.125rem]"></CalendarIcon>
                            <span>Calendar View</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </FiltersTemplate>

    <Drawer v-if="!mdAndGreater">
        <DrawerTrigger class="w-full border-b-2 border-yellow py-5">
            <span class="container flex items-center justify-between">
                <span class="font-bold text-primary">Filter By ({{ filterCount }})</span>
                <IconFilter class="size-8"></IconFilter>
            </span>
        </DrawerTrigger>
        <DrawerContent>
            <div class="flex flex-col">
                <div class="p-4">
                    <DrawerTitle>Filters</DrawerTitle>
                    <VisuallyHidden>
                        <DrawerDescription>Filter the events</DrawerDescription>
                    </VisuallyHidden>
                </div>

                <UseFiltersTemplate></UseFiltersTemplate>
            </div>

            <DrawerFooter>
                <DrawerClose as-child>
                    <BnmButton class="w-full" label="Apply"></BnmButton>
                </DrawerClose>
            </DrawerFooter>
        </DrawerContent>
    </Drawer>

    <div v-else ref="eventsListingRef">
        <UseFiltersTemplate></UseFiltersTemplate>
    </div>

    <div class="container py-10 lg:py-18">
        <EventsListingList
            v-if="!isCalendarView"
            v-model:month-year-calendar-date="filters.monthYearCalendarDate as CalendarDate"
            :filter-organiser-ids="filters.organiser"
            :filter-type-ids="filters.type"
            :filter-platform-ids="filters.platform"
            :filter-audience-ids="filters.audience"
            :filter-count="filterCount"
            :filter-sort-mode="filters.sortMode">
        </EventsListingList>
        <EventsListingCalendar
            v-else
            v-model:month-year-calendar-date="filters.monthYearCalendarDate as CalendarDate"
            :filter-organiser-ids="filters.organiser"
            :filter-type-ids="filters.type"
            :filter-platform-ids="filters.platform"
            :filter-audience-ids="filters.audience">
        </EventsListingCalendar>
    </div>
</template>
